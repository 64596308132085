import { Injectable } from '@angular/core';
import { Shared } from '../shared.class';
import { HttpClient } from '@angular/common/http';
import { ModelService } from './model.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AntwortenService {

  constructor(
    private http: HttpClient,
    private modelSrv: ModelService,
    private router: Router
  ) { }


  updateAnswer(userid, questionNr, answer) {
    return this.http.post(
      Shared.url + "php/updateAnswer.php",
      {
        'userid': userid,
        'questionNr': questionNr,
        'answer': answer
      },
      { responseType: 'text' }
    ).toPromise()
      .then(
        (json: any) => {

          if (json == 'deny') {
            this.router.navigate(['/login']);
            return "deny";
          } else {
            return json;
          }

        }
      );
  }

  getAllAnswers() {
    var userid = this.modelSrv.getUserid();

    return this.http.post(
      Shared.url + "php/getAllAnswers.php",
      { 'userid': userid },
      { responseType: 'text' }
    ).toPromise()
      .then(
        (json: any) => {

          if (json == 'deny') {
            this.router.navigate(['/login']);
            return [];
          } else {
            return JSON.parse(json).eignungstest[0];
          }

        }
      );
  }

}
