import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModelService } from './model.service';
import { Shared } from '../shared.class';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  constructor(private http: HttpClient,
    private modelSrv: ModelService) { }

  // loginCheck(userid) {
  //   this.modelSrv.setUserid(userid);
  //   let params = new HttpParams().set("userid", userid);
  //   return this.http.get(Shared.url + 'php/login.php', { params: params })
  //     .toPromise()
  //     .then((data: Response) => {
  //       return data;
  //     }, (fail: any) => {
  //       return false;
  //     });
  // }

  loginCheck(userid: string) {
    this.modelSrv.setUserid(userid);
    return this.http.post(Shared.url + "php/login.php", { "userid": userid }, { responseType: 'text' })
      .toPromise()
      .then(
        (json: any) => {
          return JSON.parse(json);
        }
      );
  }

}
