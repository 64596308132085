import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModelService {

  constructor() { }

  private _timer: number = 0;

  setTimer(timer: number) {
    this._timer = timer;
  }

  getTimer(): number {
    return this._timer;
  }

  private _userid: string = '';

  setUserid(userid: string) {
    this._userid = userid;
  }

  getUserid(): string {
    return this._userid;
  }

  private _questionNr: number = 0;

  setQuestionNr(questionNr: number) {
    this._questionNr = questionNr;
  }

  getQuestionNr(): number {
    return this._questionNr;
  }
}
