import { Component, Input, OnInit } from '@angular/core';
import { AntwortenService } from 'src/app/services/antworten.service';
import { ModelService } from 'src/app/services/model.service';
import { SendDataService } from 'src/app/services/send-data.service';

@Component({
  selector: 'app-image-text-and-input',
  templateUrl: './image-text-and-input.component.html',
  styleUrls: ['./image-text-and-input.component.scss']
})
export class ImageTextAndInputComponent implements OnInit {
  @Input() arr: any;

  constructor(private sendSrv : SendDataService,
    private antwortenSrv: AntwortenService,
              private modelSrv : ModelService) { }

  ngOnInit() {
  }

  onSubmit(){
    var arr = [];
    for(var i=0; i< this.arr.eintraege.length; i++){
      switch (this.arr.eintraege[i].answerType) {
        case 'input':
          switch (this.arr.eintraege[i].inputNr) {
            case '1':
              arr.push(this.arr.eintraege[i].comment1);
              break;

            case '3':
              arr.push(this.arr.eintraege[i].comment1+'_'+this.arr.eintraege[i].comment2+'_'+this.arr.eintraege[i].comment3);
              break;
          }

          break;

        case 'choice':
          arr.push(this.arr.eintraege[i].selectedChoice);
          break;
      }
    }
    this.antwortenSrv.updateAnswer(this.modelSrv.getUserid(), this.modelSrv.getQuestionNr(), arr).then(
      (val) => {
          if(val != 'updated'){
            alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an den Administrator.');
          }
          else{
            if(this.modelSrv.getQuestionNr()==14){
              this.sendSrv.emittSubmit('submit');
            }
            else{
              this.sendSrv.emittSubmit('next');
            }
          }
      },
      response => {
          // console.log("PATCH call in error", response);
          alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an den Administrator.');
      });
  }

}
