import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { Shared } from '../shared.class';
import { HttpClient } from '@angular/common/http';
import { ModelService } from './model.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn = false;

  constructor(
    private loginSrv: LoginService,
    private http: HttpClient,
    private modelSrv: ModelService
  ) { }

  //to check the loggedIn
  isAuthenticated() {
    const promise = new Promise(
      (resolve, reject) => {
        setTimeout(() => {
          resolve(this.loggedIn);
        }, 500);
      }
    );
    return promise;
  }

  login(userid) {
    return this.loginSrv.loginCheck(userid).then(
      res => {
        if (res['firstlogin'] == '') {
          this.loggedIn = false;
          return [false, 'Ihr ID ist ungültig.'];
        }
        else if (res['rest'] == 0) {
          this.loggedIn = false;
          return [false, 'Die 60 Minuten seit dem ersten Login sind abgelaufen.'];
        }
        else if (res['rest'] > 0 && res['firstlogin'] != '') {
          this.loggedIn = true;
          return [true, res['rest']];
        }
      },
      err => console.log(err)
    );

  }

  logout() {
    this.loggedIn = false;

    this.modelSrv.setQuestionNr(0);
    this.modelSrv.setTimer(0);
    this.modelSrv.setUserid('');

    return this.http.post(
      Shared.url + "php/logout.php", { responseType: 'text' }).toPromise().then();
  }
}
