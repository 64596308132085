import { Component, Input } from '@angular/core';
import { AntwortenService } from 'src/app/services/antworten.service';
import { ModelService } from 'src/app/services/model.service';
import { SendDataService } from 'src/app/services/send-data.service';

@Component({
  selector: 'app-image-and-input',
  templateUrl: './image-and-input.component.html',
  styleUrls: ['./image-and-input.component.scss']
})
export class ImageAndInputComponent {
  @Input() arr: any;
  @Input() imgW: number;
  @Input() imgH:number;

  constructor(private sendSrv : SendDataService,
              private modelSrv : ModelService,
              private antwortenSrv: AntwortenService) { }

  ngOnInit() {
  }

  onSubmit(){
    var arr = [];
    for(var i=0; i< this.arr.eintraege.length; i++){
        arr.push(this.arr.eintraege[i].comment);
    }
    this.antwortenSrv.updateAnswer(this.modelSrv.getUserid(), this.modelSrv.getQuestionNr(), arr).then(
      (val) => {
          if(val != 'updated'){
            alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an den Administrator.');
          }
          else{
            this.sendSrv.emittSubmit('next');
          }
      },
      response => {
          // console.log("PATCH call in error", response);
          alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an den Administrator.');
      });
  }

}
