import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ModelService } from '../services/model.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  errorMsg: string = '';
  errorMsgBoolean: boolean = false;

  constructor(private authSrv: AuthService,
    private modelSrv: ModelService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      if (params['logout'] == 'logout') {
        this.errorMsgBoolean = true;
        this.errorMsg = 'Die 60 Minuten seit dem ersten Login sind abgelaufen.';
      }

    });
  }

  async onSubmit(form: NgForm) {

    var res = await this.authSrv.login(form.value.password);

    switch (res[0]) {
      case false:
        this.errorMsgBoolean = true;
        this.errorMsg = res[1];
        break;

      case true:
        this.modelSrv.setTimer(res[1]);
        this.modelSrv.setQuestionNr(1);
        this.router.navigate(['/eignungstest']);
        break;
    }

  }

}
