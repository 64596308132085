import { Component, Input, OnInit } from '@angular/core';
import { AntwortenService } from 'src/app/services/antworten.service';
import { ModelService } from 'src/app/services/model.service';
import { SendDataService } from 'src/app/services/send-data.service';

@Component({
  selector: 'app-label-and-input',
  templateUrl: './label-and-input.component.html',
  styleUrls: ['./label-and-input.component.scss']
})
export class LabelAndInputComponent implements OnInit {
  @Input() arr: any;
  @Input() inputNr: string;

  meldung: boolean = false;

  constructor(private sendSrv: SendDataService,
    private antwortenSrv: AntwortenService,
    public modelSrv: ModelService) {
    this.sendSrv.save$.subscribe(
      data => {
        this.onSubmit();
      }
    );
  }

  ngOnInit() {
  }

  onSubmit() {
    this.meldung = false;
    var boo = true;

    for (var i = 0; i < this.arr.eintraege.length; i++) {
      if (
        (this.arr.eintraege[i].comment.includes('&')) ||
        (this.arr.eintraege[i].comment.includes('"')) ||
        (this.arr.eintraege[i].comment.includes("'"))) {
        console.log('Hi samah');
        boo = false;
        break;
      }
    }

    if (boo) {
      var arr = [];
      for (var i = 0; i < this.arr.eintraege.length; i++) {

        if (typeof this.arr.eintraege[i].comment2 !== 'undefined') {
          arr.push(this.arr.eintraege[i].comment + '_' + this.arr.eintraege[i].comment2);
        }
        else {
          arr.push(this.arr.eintraege[i].comment);
        }
      }
      this.antwortenSrv.updateAnswer(this.modelSrv.getUserid(), this.modelSrv.getQuestionNr(), arr).then(
        (val) => {
          if (val != 'updated') {
            alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an den Administrator.');
          }
          else {

            this.sendSrv.emittSubmit('next');

          }
        },
        response => {
          // console.log("PATCH call in error", response);
          alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an dem Administrator!');
        });
    } else {
      this.meldung = true;
    }

  }

}
