import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../services/login.service';
import { ModelService } from '../services/model.service';
import { SendDataService } from '../services/send-data.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit {
  timer: number = 0;
  hh_mm_ss;

  constructor(private modelSrv: ModelService,
    private route: ActivatedRoute,
    private router: Router,
    private loginSrv: LoginService,
    private sendSrv: SendDataService) {
    this.timer = this.modelSrv.getTimer();
    setInterval(() => {
      this.timer--;
      this.hh_mm_ss = new Date(this.timer * 1000).toISOString().substr(11, 8);
      if (this.timer == 0) {
        // this.loginSrv.saveAllAnswers();
        this.sendSrv.emittSubmit('timeOut');
      }
    }, 1000);
  }

  ngOnInit() { }

}
