import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule, MatInputModule } from '@angular/material'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { RadioButtonAndCommentComponent } from './components/radio-button-and-comment/radio-button-and-comment.component';
import { RadioChoiceComponent } from './components/radio-choice/radio-choice.component';
import { LabelAndInputComponent } from './components/label-and-input/label-and-input.component';
import { ImageAndInputComponent } from './components/image-and-input/image-and-input.component';
import { ImageTextAndInputComponent } from './components/image-text-and-input/image-text-and-input.component';
import { MultipleChoiceComponent } from './components/multiple-choice/multiple-choice.component';
import { LoginComponent } from './login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { EinstellungstestComponent } from './einstellungstest/einstellungstest.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { TimerComponent } from './timer/timer.component';
import { DatenschutzComponent } from './general-pages/datenschutz/datenschutz.component';
import { ImpressumComponent } from './general-pages/impressum/impressum.component';
import { LogoutComponent } from './logout/logout.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: 'login/:logout', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  // { path: 'einstellungstest', canActivate: [AuthGuardService], component: EinstellungstestComponent }
  { path: 'eignungstest', canActivate: [AuthGuardService], component: EinstellungstestComponent }

];

@NgModule({
  declarations: [
    AppComponent,
    RadioButtonAndCommentComponent,
    RadioChoiceComponent,
    LabelAndInputComponent,
    ImageAndInputComponent,
    ImageTextAndInputComponent,
    MultipleChoiceComponent,
    LoginComponent,
    EinstellungstestComponent,
    TimerComponent,
    DatenschutzComponent,
    ImpressumComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatRadioModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    MatInputModule
  ],
  exports: [RouterModule],
  providers: [AuthService, AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
