import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModelService } from '../services/model.service';
import { SendDataService } from '../services/send-data.service';
import { AntwortenService } from '../services/antworten.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-einstellungstest',
  templateUrl: './einstellungstest.component.html',
  styleUrls: ['./einstellungstest.component.scss']
})
export class EinstellungstestComponent implements OnInit {
  title = 'fragenbogen';

  kategorieTitle: string = "";
  aufgabeNummer: string = "";

  buttonsArr: any[] = [{}];

  selectedButton: number = 0;

  constructor(
    private sendSrv: SendDataService,
    private modelSrv: ModelService,
    private router: Router,
    private antwortenSrv: AntwortenService,
    private authSrv: AuthService
  ) {


    sendSrv.submit$.subscribe(
      submitVal => {
        window.scrollTo(0, 0);
        switch (submitVal) {
          case 'next':
            this.onclickButton(this.selectedButton + 1);
            modelSrv.setQuestionNr(this.selectedButton + 1);
            break;

          case 'submit':
            this.authSrv.logout();
            this.router.navigate(['/logout']);
            break;

          case 'timeOut':
            this.authSrv.logout();
            this.router.navigate(['/logout']);
            break;

          default:
            break;
        }
      }
    );
  }


  getSelectedComment(val) {
    switch (val) {
      case '':
        return ['', ''];

      case 't1':
        return ['1', ''];

      default:
        return ['2', val];
    }
  }

  ngOnInit() {

    this.antwortenSrv.getAllAnswers().then((data: any) => {

      if (!data['q1_1']) { data['q1_1'] = "" }
      if (!data['q1_2']) { data['q1_2'] = "" }
      if (!data['q1_3']) { data['q1_3'] = "" }

      if (!data['q2_1']) { data['q2_1'] = "" }
      if (!data['q2_2']) { data['q2_2'] = "" }
      if (!data['q2_3']) { data['q2_3'] = "" }
      if (!data['q2_4']) { data['q2_4'] = "" }
      if (!data['q2_5']) { data['q2_5'] = "" }
      if (!data['q2_6']) { data['q2_6'] = "" }
      if (!data['q2_7']) { data['q2_7'] = "" }
      if (!data['q2_8']) { data['q2_8'] = "" }
      if (!data['q2_9']) { data['q2_9'] = "" }
      if (!data['q2_10']) { data['q2_10'] = "" }
      if (!data['q2_11']) { data['q2_11'] = "" }
      if (!data['q2_12']) { data['q2_12'] = "" }
      if (!data['q2_13']) { data['q2_13'] = "" }
      if (!data['q2_14']) { data['q2_14'] = "" }
      if (!data['q2_15']) { data['q2_15'] = "" }

      if (!data['q3_1']) { data['q3_1'] = "" }
      if (!data['q3_2']) { data['q3_2'] = "" }
      if (!data['q3_3']) { data['q3_3'] = "" }
      if (!data['q3_4']) { data['q3_4'] = "" }
      if (!data['q3_5']) { data['q3_5'] = "" }
      if (!data['q3_6']) { data['q3_6'] = "" }
      if (!data['q3_7']) { data['q3_7'] = "" }
      if (!data['q3_8']) { data['q3_8'] = "" }
      if (!data['q3_9']) { data['q3_9'] = "" }
      if (!data['q3_10']) { data['q3_10'] = "" }

      if (!data['q4_1']) { data['q4_1'] = "" }
      if (!data['q4_2']) { data['q4_2'] = "" }
      if (!data['q4_3']) { data['q4_3'] = "" }
      if (!data['q4_4']) { data['q4_4'] = "" }
      if (!data['q4_5']) { data['q4_5'] = "" }
      if (!data['q4_6']) { data['q4_6'] = "" }
      if (!data['q4_7']) { data['q4_7'] = "" }
      if (!data['q4_8']) { data['q4_8'] = "" }
      if (!data['q4_9']) { data['q4_9'] = "" }
      if (!data['q4_10']) { data['q4_10'] = "" }

      if (!data['q5_1']) { data['q5_1'] = "" }
      if (!data['q5_2']) { data['q5_2'] = "" }
      if (!data['q5_3']) { data['q5_3'] = "" }
      if (!data['q5_4']) { data['q5_4'] = "" }
      if (!data['q5_5']) { data['q5_5'] = "" }

      if (!data['q6_1']) { data['q6_1'] = "" }
      if (!data['q6_2']) { data['q6_2'] = "" }
      if (!data['q6_3']) { data['q6_3'] = "" }
      if (!data['q6_4']) { data['q6_4'] = "" }
      if (!data['q6_5']) { data['q6_5'] = "" }

      if (!data['q8_1']) { data['q8_1'] = "" }
      if (!data['q8_2']) { data['q8_2'] = "" }
      if (!data['q8_3']) { data['q8_3'] = "" }

      if (!data['q9_1']) { data['q9_1'] = "" }
      if (!data['q9_2']) { data['q9_2'] = "" }

      if (!data['q10_1']) { data['q10_1'] = "" }

      this.buttonsArr = [
        /*1*/
        {
          title: 'Allgemeines',
          aufgabe: 'Motivation',
          aufgabeBeschreibung: 'Offene Fragen',
          aufgabeType: 'labelAndInput',
          inputNr: '5',
          inhalt: {
            eintraege: [
              {
                label: 'Aus welchen Gründen haben Sie sich für den Beruf des Triebfahrzeugführers entschieden?',
                comment: data['q1_1'],
                bemerkung: 'Sie dürfen 1000 Zeichen schreiben.'
              },
              {
                label: 'Bitte beschreiben Sie in vollständigen Sätzen, wie Sie sich einen typischen Arbeitstag eines Triebfahrzeugführers vorstellen.',
                comment: data['q1_2'],
                bemerkung: 'Sie dürfen 1000 Zeichen schreiben.'
              },
              {
                label: 'Wie bewerten Sie die Balance zwischen Beruf und Privatleben im Beruf des Triebfahrzeugführers und welche möglichen Herausforderungen sehen Sie in diesem Zusammenhang?',
                comment: data['q1_3'],
                bemerkung: 'Sie dürfen 1000 Zeichen schreiben.'
              },
            ]
          }
        },

        /*2*/
        {
          title: 'Sprachkenntnisse',
          aufgabe: 'Aufgabe 1',
          aufgabeBeschreibung: 'Überprüfen Sie die Schreibweise der nachfolgenden Worte. Kreuzen Sie richtig oder falsch an. Bei falscher Schreibweise korrigieren Sie das Wort bitte in der rechten Spalte.',
          aufgabeType: 'RadioButtonAndComment',
          inhalt: {
            showRichtigFalse: true,
            eintraege: [
              {
                label: 'Signaal',
                selected: this.getSelectedComment(data['q2_1'])[0],
                comment: this.getSelectedComment(data['q2_1'])[1]
              },
              {
                label: 'rangieren',
                selected: this.getSelectedComment(data['q2_2'])[0],
                comment: this.getSelectedComment(data['q2_2'])[1]
              },
              {
                label: 'Lokführer',
                selected: this.getSelectedComment(data['q2_3'])[0],
                comment: this.getSelectedComment(data['q2_3'])[1]
              },
              {
                label: 'Eisenbann',
                selected: this.getSelectedComment(data['q2_4'])[0],
                comment: this.getSelectedComment(data['q2_4'])[1]
              },
              {
                label: 'Nohtausstieg',
                selected: this.getSelectedComment(data['q2_5'])[0],
                comment: this.getSelectedComment(data['q2_5'])[1]
              },
              {
                label: 'Verkehrscaos',
                selected: this.getSelectedComment(data['q2_6'])[0],
                comment: this.getSelectedComment(data['q2_6'])[1]
              },
              {
                label: 'Kupplung',
                selected: this.getSelectedComment(data['q2_7'])[0],
                comment: this.getSelectedComment(data['q2_7'])[1]
              },
              {
                label: 'Bahnhof',
                selected: this.getSelectedComment(data['q2_8'])[0],
                comment: this.getSelectedComment(data['q2_8'])[1]
              },
              {

                label: 'Schichtdienst',
                selected: this.getSelectedComment(data['q2_9'])[0],
                comment: this.getSelectedComment(data['q2_9'])[1]
              },
              {
                label: 'Eisenbahnbundesamt',
                selected: this.getSelectedComment(data['q2_10'])[0],
                comment: this.getSelectedComment(data['q2_10'])[1]
              },
              {
                label: 'Schiehne',
                selected: this.getSelectedComment(data['q2_11'])[0],
                comment: this.getSelectedComment(data['q2_11'])[1]
              },
              {
                label: 'Gleisbeet',
                selected: this.getSelectedComment(data['q2_12'])[0],
                comment: this.getSelectedComment(data['q2_12'])[1]
              },
              {
                label: 'Elektromotohr',
                selected: this.getSelectedComment(data['q2_13'])[0],
                comment: this.getSelectedComment(data['q2_13'])[1]
              },
              {
                label: 'Waggong',
                selected: this.getSelectedComment(data['q2_14'])[0],
                comment: this.getSelectedComment(data['q2_14'])[1]
              },
              {
                label: 'Waiche',
                selected: this.getSelectedComment(data['q2_15'])[0],
                comment: this.getSelectedComment(data['q2_15'])[1]
              },
            ]
          }
        },

        /*3*/
        {
          title: 'Sprachkenntnisse',
          aufgabe: 'Aufgabe 2',
          aufgabeBeschreibung: 'Bei dieser Aufgabe geht es darum, die Wörter, die sich hinter dem jeweiligen „Buchstabensalat“ verbergen, zu erkennen und korrekt aufzuschreiben.',
          aufgabeType: 'labelAndInput',
          inputNr: '1',
          inhalt: {
            eintraege: [
              { label: 'GNÖIK', comment: data['q3_1'] },
              { label: 'SASWRE', comment: data['q3_2'] },
              { label: 'FFAKEE', comment: data['q3_3'] },
              { label: 'PSUEP', comment: data['q3_4'] },
              { label: 'ZLIP', comment: data['q3_5'] },
              { label: 'LABLNO', comment: data['q3_6'] },
              { label: 'REFEUHERW', comment: data['q3_7'] },
              { label: 'NKRA', comment: data['q3_8'] },
              { label: 'GZU', comment: data['q3_9'] },
              { label: 'IZIELOP', comment: data['q3_10'] }
            ]
          }
        },

        /*4*/
        {
          title: 'Mathematik und Zahlenverständnis',
          aufgabe: 'Aufgabe 1',
          aufgabeBeschreibung: 'Bitte lösen Sie die folgenden Mathematik-Aufgaben.',
          aufgabeType: 'labelAndInput',
          inputNr: '1',
          inhalt: {
            eintraege: [
              { label: '2 + 2 x 2 =', comment: data['q4_1'] },
              { label: '56 + 88 – 24 =', comment: data['q4_2'] },
              { label: '58 x 5 – 88 =', comment: data['q4_3'] },
              { label: '285 / 3 =', comment: data['q4_4'] },
              { label: '527 + 863 =', comment: data['q4_5'] },
              { label: '8342 – 537 =', comment: data['q4_6'] },
              { label: '10% von 125€ =', comment: data['q4_7'] },
              { label: '2584 - 34 =', comment: data['q4_8'] },
              { label: '0,75 + 0,25 =', comment: data['q4_9'] },
              { label: '4,50 + 2,75 =', comment: data['q4_10'] },
            ]
          }
        },

        /*5*/
        {
          title: 'Mathematik und Zahlenverständnis',
          aufgabe: 'Aufgabe 2',
          aufgabeBeschreibung: 'Bitte setzen Sie die Zahlenreihen nach rechts logisch fort.',
          aufgabeType: 'labelAndInput',
          inputNr: '2',
          inhalt: {
            eintraege: [
              {
                label: '2 3 5 9 17 33 65',
                comment: data['q5_1'].split('_')[0],
                comment2: data['q5_1'].split('_')[1]
              },
              {
                label: '12 13 11 14 10 15 9',
                comment: data['q5_2'].split('_')[0],
                comment2: data['q5_2'].split('_')[1]
              },
              {
                label: '19 17 16 14 13 11 10',
                comment: data['q5_3'].split('_')[0],
                comment2: data['q5_3'].split('_')[1]
              },
              {
                label: '3 5 7 9 11 13 15',
                comment: data['q5_4'].split('_')[0],
                comment2: data['q5_4'].split('_')[1]
              },
              {
                label: '1 2 4 8 16 32 64',
                comment: data['q5_5'].split('_')[0],
                comment2: data['q5_5'].split('_')[1]
              },
            ]
          }
        },

        /*6*/
        {
          title: 'Logik und Allgemeinwissen',
          aufgabe: 'Aufgabe 1',
          aufgabeBeschreibung: 'Offene Fragen',
          aufgabeType: 'labelAndInput',
          inputNr: '5',
          inhalt: {
            eintraege: [
              {
                label: 'Ein Zug benötigt für eine Strecke 8 Stunden, wenn er mit einer durchschnittlichen Geschwindigkeit von 100 km/h fährt. Wie schnell muss er fahren, wenn er die gleiche Strecke in 5 Stunden schaffen will?',
                comment: data['q6_1'],
                bemerkung: 'Sie dürfen 1000 Zeichen schreiben.'
              },
              {
                label: '2 Bagger heben einen Graben in genau 48 Stunden aus. Wie lange benötigen 3 Bagger?',
                comment: data['q6_2'],
                bemerkung: 'Sie dürfen 1000 Zeichen schreiben.'
              },
              {
                label: 'Was bedeuten die Abkürzungen „EBA“ und „EVU“?',
                comment: data['q6_3'],
                bemerkung: 'Sie dürfen 1000 Zeichen schreiben.'
              },
              {
                label: 'Wer ist die Stärkste? Jana ist stärker als Claudia. Frauke ist schwächer als Rosi. Rosi ist schwächer als Claudia.',
                comment: data['q6_4'],
                bemerkung: 'Sie dürfen 1000 Zeichen schreiben.'
              },
              {
                label: 'Bitte erläutern Sie das 10/4-Schichtsystem.',
                comment: data['q6_5'],
                bemerkung: 'Sie dürfen 1000 Zeichen schreiben.'
              },

            ]
          }
        },

        /*7*/
        {
          title: 'Logik und Allgemeinwissen',
          aufgabe: 'Aufgabe 2',
          aufgabeBeschreibung: 'Welcher Begriff vervollständigt das zweite Wortpaar? Klicken Sie die richtige Antwort an.',
          aufgabeType: 'radioChoice',
          inhalt: {
            eintraege: [
              {
                label: 'Sitzen : gehen = Ruhe : ?',
                auswahl: ['Bürgersteig', 'Fußgänger', 'Kraft', 'Bewegung', 'Schuhe'],
                selected: +data['q7_1']
              },

              {
                label: 'Frosch : Tierreich = Schraube : ?',
                auswahl: ['Schraubenmutter', 'Gewinde', 'Hubschrauber', 'Maschinenbau', 'Käfig'],
                selected: +data['q7_2']
              },

            ]
          }
        },

        /*8*/
        {
          title: 'Räumliches Vorstellungsvermögen',
          aufgabe: 'Aufgabe 1',
          aufgabeBeschreibung: 'Bitte tragen Sie die richtige Antwort in das hellgraue Kästchen ein.',
          aufgabeType: 'imageTextAndInput',
          inhalt: {
            eintraege: [
              {
                label: 'A2_1',
                text: 'Die links aufgeführten Stahlträgerprofile stehen zur Auswahl. Welches Stahlträgerprofil von den aufgeführten hält die stärkste Belastung aus?',
                answerType: 'input',
                inputNr: '1',
                comment1: data['q8_1']
              },
              {
                label: 'A2_3',
                text: 'Welches Brett (A, B oder C) kann die schwerste Last oben aufliegend tragen?',
                answerType: 'input',
                inputNr: '1',
                comment1: data['q8_2']
              },
              {
                label: 'A2_4',
                text: 'Welches der drei Gewichte kann mit der geringsten Anstrengung gehoben werden?',
                answerType: 'input',
                inputNr: '1',
                comment1: data['q8_3']
              },

            ]
          }
        },

        /*9*/
        {
          title: 'Technisches Grundwissen',
          aufgabe: 'Aufgabe 1',
          aufgabeBeschreibung: 'Offene Fragen',
          aufgabeType: 'labelAndInput',
          inputNr: '5',
          inhalt: {
            eintraege: [
              {
                label: 'In Ihrem Arbeitsbereich erkennen Sie ein defektes elektrisches Gerät. Welche Maßnahmen haben Sie durchzuführen?',
                comment: data['q9_1'],
                bemerkung: 'Sie dürfen 1000 Zeichen schreiben.'
              },
              {
                label: 'Schildern Sie die einzelnen Arbeitsschritte beim Löten.', comment: data['q9_2'],
                bemerkung: 'Sie dürfen 1000 Zeichen schreiben.'
              },

            ]
          }
        },


        /*10*/
        {
          title: 'Technisches Grundwissen',
          aufgabe: 'Aufgabe 2',
          aufgabeBeschreibung: 'Multiple Choice: Von den Antwortoptionen sind eine oder mehrere richtig.',
          aufgabeType: 'multipleChoice',
          inhalt: {
            eintraege: [
              {
                label: 'Welche Aussagen sind richtig?',
                id: 1,
                auswahl: [
                  {
                    id: 1,
                    name: 'Ampere ist die Einheit für den Widerstand',
                    selected: data['q10_1'].includes("1")
                  },
                  {
                    id: 2,
                    name: 'Volt ist die Einheit für die Spannung',
                    selected: data['q10_1'].includes("2")
                  },
                  {
                    id: 3,
                    name: 'Ohm ist die Einheit für die Spannung',
                    selected: data['q10_1'].includes("3")
                  },
                  {
                    id: 4,
                    name: 'Ampere ist die Einheit für die Stromstärke',
                    selected: data['q10_1'].includes("4")
                  },

                  {
                    id: 5,
                    name: 'Volt ist die Einheit für die Stromstärke',
                    selected: data['q10_1'].includes("5")
                  },
                  {
                    id: 6,
                    name: 'Ohm ist die Einheit für den Widerstand',
                    selected: data['q10_1'].includes("6")
                  },

                ]
              },

            ]
          }
        },
      ];
    }, (fail: any) => {
      return 'fail'
    });
  }

  onUpDownArrow(sign) {
    window.scrollTo(0, 0);
    switch (sign) {
      case '+':
        // this.sendSrv.emittSave(this.selectedButton);
        this.selectedButton++;
        this.modelSrv.setQuestionNr(this.selectedButton + 1);
        break;

      case '-':
        // this.sendSrv.emittSave(this.selectedButton);
        this.selectedButton--;
        this.modelSrv.setQuestionNr(this.selectedButton + 1);
        break;
    }
  }

  onclickButton(i) {
    window.scrollTo(0, 0);
    this.selectedButton = i;
    this.modelSrv.setQuestionNr(this.selectedButton + 1);
  }

}
