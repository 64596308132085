import { Component, Input, OnInit } from '@angular/core';
import { AntwortenService } from 'src/app/services/antworten.service';
import { ModelService } from 'src/app/services/model.service';
import { SendDataService } from 'src/app/services/send-data.service';

@Component({
  selector: 'app-multiple-choice',
  templateUrl: './multiple-choice.component.html',
  styleUrls: ['./multiple-choice.component.scss']
})
export class MultipleChoiceComponent implements OnInit {
  @Input() arr: any;

  constructor(private sendSrv: SendDataService,
    private antwortenSrv: AntwortenService,
    private modelSrv: ModelService) { }

  ngOnInit() { }

  onSubmit() {
    var arr = [];
    for (var i = 0; i < this.arr.length; i++) {
      var pushed = '';
      for (var j = 0; j < this.arr[i].auswahl.length; j++) {
        if (this.arr[i].auswahl[j].selected == true) {
          pushed += (j + 1);
        }
      }
      arr.push(pushed);
    }

    this.antwortenSrv.updateAnswer(this.modelSrv.getUserid(), this.modelSrv.getQuestionNr(), arr).then(
      (val) => {
        if (val != 'updated') {
          alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an den Administrator.');
        }
        else {
          this.sendSrv.emittSubmit('submit');
        }
      },
      response => {
        alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an dem Administrator!');
      });
  }

}
