import { Component, Input, OnInit } from '@angular/core';
import { AntwortenService } from 'src/app/services/antworten.service';
import { ModelService } from 'src/app/services/model.service';
import { SendDataService } from 'src/app/services/send-data.service';

@Component({
  selector: 'app-radio-button-and-comment',
  templateUrl: './radio-button-and-comment.component.html',
  styleUrls: ['./radio-button-and-comment.component.scss']
})
export class RadioButtonAndCommentComponent implements OnInit {
  @Input() arr: any;

  constructor(private sendSrv: SendDataService,
    private antwortenSrv: AntwortenService,
    private modelSrv: ModelService) {
    this.sendSrv.save$.subscribe(
      data => {
        this.onSubmit();
      }
    );
  }

  ngOnInit() {
  }

  onSubmit() {
    var arr = [];

    // console.log(this.arr);

    for (var i = 0; i < this.arr.eintraege.length; i++) {
      if (this.arr.eintraege[i].selected == '1') {
        arr.push('t1');
      }
      else {
        arr.push(this.arr.eintraege[i].comment);
      }
    }
    this.antwortenSrv.updateAnswer(this.modelSrv.getUserid(), this.modelSrv.getQuestionNr(), arr).then(
      (val) => {
        if (val != 'updated') {
          alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an den Administrator.');
        }
        else {
          this.sendSrv.emittSubmit('next');
        }
      },
      response => {
        // console.log("PATCH call in error", response);
        alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an den Administrator.');
      });
  }

}
