import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendDataService {

  constructor() { }

  private submit = new Subject<string>();
  submit$ = this.submit.asObservable();
  emittSubmit(val: string) {
    this.submit.next(val);
  }

  private timer = new Subject<number>();
  timer$ = this.timer.asObservable();
  emittTimer(val: number) {
    this.timer.next(val);
  }

  private save = new Subject<number>();
  save$ = this.save.asObservable();
  emittSave(val: number) {
    this.save.next(val);
  }

}
