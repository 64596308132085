import { Component, Input, OnInit } from '@angular/core';
import { AntwortenService } from 'src/app/services/antworten.service';
import { ModelService } from 'src/app/services/model.service';
import { SendDataService } from 'src/app/services/send-data.service';

@Component({
  selector: 'app-radio-choice',
  templateUrl: './radio-choice.component.html',
  styleUrls: ['./radio-choice.component.scss']
})
export class RadioChoiceComponent implements OnInit {
  @Input() arr: any;

  constructor(private sendSrv: SendDataService,
    private modelSrv: ModelService,
    private antwortenSrv: AntwortenService) { }

  ngOnInit() {
  }

  onSubmit() {
    var arr = [];
    for (var i = 0; i < this.arr.eintraege.length; i++) {
      arr.push(this.arr.eintraege[i].selected);
    }
    this.antwortenSrv.updateAnswer(this.modelSrv.getUserid(), this.modelSrv.getQuestionNr(), arr).then(
      (val) => {
        if (val != 'updated') {
          alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an den Administrator.');
        }
        else {
          if (this.modelSrv.getQuestionNr() == 14) {
            this.sendSrv.emittSubmit('submit');
          }
          else {
            this.sendSrv.emittSubmit('next');
          }
        }
      },
      response => {
        // console.log("PATCH call in error", response);
        alert('Ihre Antworten konnten nicht gespeichert werden. Wenden Sie sich an dem Administrator!');
      });
  }

}
